import React from 'react';
import EmwProgressCircle from '../../../../../../components/EmwProgressCircle/EmwProgressCircle';

export default function LoadingContainer({ isLoading }) {
  if (isLoading) {
    return (
      <div
        style={{
          position: 'absolute',
          zIndex: 9999,
          width: '100%',
          height: '100%',
          bottom: '0',
        }}
      >
        <div className="flex w-full h-full justify-center items-center">
          <EmwProgressCircle size="large" />
        </div>
      </div>
    );
  }

  return null;
}
