import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';
import { Grid } from '@mui/material';
import GeneralFiltersContainer from '../../../../../../components/EmwGeneralFilters/GeneralFiltersContainer';
import { DrillToDetailsStyled } from './styled';
import EmwButton from '../../../../../../components/EmwButton/EmwButton';
import EmwTypography from '../../../../../../components/EmwTypography/EmwTypography';
import MoodAndDistribution from '../MoodDistribution/MoodAndDistribution';
import ConfidenceToDeliverDistribution from '../ConfidenceToDeliverDistribution/ConfidenceToDeliverDistribution';
import DetailsTable from '../DetailsTable/DetailsTable';
import { useDispatch, useSelector } from 'react-redux';
import {
  getIsFilteredMcdData,
  getMcdDrillProcessData,
  getMcdDrillProcessFilteredData,
  getMcdDrillToData,
  getMcdDrillToPayload,
} from '../../../../redux/selectors';
import { setIsFilteredMcdData, setMcdDrillToPayload } from '../../../../redux/actions';
import { CHART_TYPE, transformProcessedData } from '../../utils';
import { selectedDrillToDetailFilters } from '../../../../../../components/EmwGeneralFilters/redux/selectors';
import DatePeriod from '../DatePeriod/DatePeriod';
import useGeneralFilters from '../../../../../../components/EmwGeneralFilters/hooks/useGeneralFilters';
import { treeDataSelector } from '../../../../../header/redux/selectors';
import { DRILL_REQUESTS } from './constants';
import useRequests from '../../useRequests';
import { setIsHza } from '../../../../../footer/redux/actions';

export default function DrillToDetails() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { handleRequests, moodWords, confidenceWords } = useRequests();
  const { buildFiltersWithParam } = useGeneralFilters();
  const reqPayload = useSelector(getMcdDrillToPayload);
  const processData = useSelector(getMcdDrillProcessData);
  const processFilteredData = useSelector(getMcdDrillProcessFilteredData);
  const selectedFilters = useSelector(selectedDrillToDetailFilters);
  const mcdData = useSelector(getMcdDrillToData);
  const treeData = useSelector(treeDataSelector);
  const tableWrapperRef = useRef(null);
  const [isFetching, setIsFetching] = useState(false);
  const [mood, setMood] = useState(transformProcessedData(processData, CHART_TYPE.MOOD));
  const isFilteredData = useSelector(getIsFilteredMcdData);
  const [confidence, setConfidence] = useState(
    transformProcessedData(processData, CHART_TYPE.CONFIDENCE)
  );
  const [filteredMood, setFilteredMood] = useState(
    transformProcessedData(processFilteredData, CHART_TYPE.MOOD)
  );
  const [filteredConfidence, setFilteredConfidence] = useState(
    transformProcessedData(processFilteredData, CHART_TYPE.CONFIDENCE)
  );
  const [selectedBar, setSelectedBar] = useState(null);
  const [selectedBars, setSelectedBars] = useState([]);
  const [chartType, setChartType] = useState(null);

  const [loadingState, setLoadingState] = useState({
    [DRILL_REQUESTS.MCD.DATA]: false,
  });

  useEffect(() => {
    if (isFilteredData) {
      setFilteredMood(transformProcessedData(processData, CHART_TYPE.MOOD));
      setFilteredConfidence(transformProcessedData(processData, CHART_TYPE.CONFIDENCE));
    } else {
      setMood(transformProcessedData(processData, CHART_TYPE.MOOD));
      setConfidence(transformProcessedData(processData, CHART_TYPE.CONFIDENCE));
    }
  }, [processData, isFilteredData]);

  useEffect(() => {
    if (treeData.list.hasOwnProperty('data') && treeData.list.data.regions.length) {
      buildFiltersWithParam(treeData.list.data, 'showInDrillToDetails');
    }
  }, [treeData.list.data]);

  useEffect(() => {
    const _payload = { ...reqPayload };
    _payload.organizationFilters.countries = selectedFilters.country.map(country => country.id);
    _payload.organizationFilters.gbus = selectedFilters.gbu.map(gbu => gbu.id);
    _payload.organizationFilters.bls = selectedFilters.bl.map(bl => bl.id);
    _payload.organizationFilters.ccs = selectedFilters.cc.map(cc => cc.id);
    dispatch(setMcdDrillToPayload(_payload));
    handleRequests(_payload);
  }, [selectedFilters]);

  const handleBack = async () => {
    dispatch(setIsHza(false));
    navigate('/moodandconfidence');
  };

  const handleClick = value => {
    console.log('handleClick', value);
  };

  const handleBarClick = (event, node, type) => {
    const _reqPayload = { ...reqPayload };

    if (chartType === type && selectedBars.includes(node.indexValue)) {
      setChartType(null);
      setSelectedBars(currentSelectedBars =>
        currentSelectedBars.filter(item => item !== node.indexValue)
      );

      dispatch(setIsFilteredMcdData(false));
      _reqPayload[type.toLowerCase()] = [];
    } else {
      setSelectedBar(node.indexValue);
      setSelectedBars(currentSelectedBars => [...currentSelectedBars, node.indexValue]);
      setChartType(type);
      dispatch(setIsFilteredMcdData(true));

      _reqPayload[type.toLowerCase()] = [node.data.label];
    }
    handleRequests(_reqPayload);
  };

  // console.log('selectedBars', selectedBars);

  const handleConfidenceBarClick = (node, event) =>
    handleBarClick(event, node, CHART_TYPE.CONFIDENCE);
  const handleMoodBarClick = (node, event) => handleBarClick(event, node, CHART_TYPE.MOOD);

  return (
    <GeneralFiltersContainer pt={'pt-xxs'} param={'showInDrillToDetails'}>
      <DrillToDetailsStyled className="flex flex-col flex-1 overflow-auto px-xs pb-xs">
        <div className="page-title my-m">
          <EmwButton
            label="Back"
            variant="ghost"
            color="neutral"
            leftIcon="arrow_back_ios"
            onClick={handleBack}
          />
          <EmwTypography classes="text-primary-500 ml-xs" fontSize="5" fontWeight="bold">
            Mood & Confidence to deliver - Drill to details
          </EmwTypography>
          <div className="ml-auto">
            <DatePeriod />
          </div>
        </div>
        <div className="row">
          <Grid container>
            <Grid item md={12} lg={6} className="pr-xs">
              <MoodAndDistribution
                data={mood}
                words={moodWords}
                handleClick={handleClick}
                handleBarClick={handleMoodBarClick}
                chartType={chartType}
                filteredData={filteredMood}
                selectedBars={selectedBar}
                isFilteredData={isFilteredData}
              />
            </Grid>
            <Grid item md={12} lg={6} className="pl-xs">
              <ConfidenceToDeliverDistribution
                data={confidence}
                words={confidenceWords}
                handleClick={handleClick}
                handleBarClick={handleConfidenceBarClick}
                chartType={chartType}
                filteredData={filteredConfidence}
                selectedBars={selectedBar}
                isFilteredData={isFilteredData}
              />
            </Grid>
          </Grid>
        </div>
        <div className="row  mt-m overflow-hidden flex-1" ref={tableWrapperRef}>
          <div className="flex-1 bg-white-100">
            <DetailsTable
              tableData={mcdData}
              tableWrapperRef={tableWrapperRef}
              setLoading={setLoadingState}
              isLoading={loadingState[DRILL_REQUESTS.MCD.DATA]}
            />
          </div>
        </div>
      </DrillToDetailsStyled>
    </GeneralFiltersContainer>
  );
}
