import React, { useEffect, useRef, useState } from 'react';
import { StyledHeader } from './styled';
import { Box, Divider } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom-v5-compat';
import { isDrilledSelector } from '../../powerBi/redux/selectors';
import clx from 'classnames';
import { setHzaIframeLoaded } from '../redux/actions';
import { biPropsSelector, treeDataSelector, userSelector } from '../redux/selectors';
import {
  setFavoriteOrganizations,
  setGbuList,
  setUnderMyResponsability,
} from '../../organization/redux/actions';
import { setAccessToken, setReportFilters, setUserOrgs } from '../../powerBi/redux/actions';
import LandingPageTour from '../../../components/Tour/LandingPageTour/LandingPageTour';
import UserGuideButton from '../../../components/Tour/UserGuideButton';
import OrganizationTour from '../../../components/Tour/OrganizationTour/OrganizationTour';
import DiagramContextProvider from '../../organization/components/Diagram/context/DiagramContext';
import JobFamilyMenu from '../../jobFamily/components/JobFamily/JobFamilyMenu';
import EmwOpenHzaBtn from '../../../components/EmwOpenHzaBtn/EmwOpenHzaBtn';
import EmwBrand from '../../../components/EmwBrand/EmwBrand';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../../../auth/authConfig';
import { useLoadDependencies } from './useLoadDependencies';
import UserMenu from './UserMenu/UserMenu';
import AdministrationMenu from '../../administration/components/AdministrationMenu/AdministrationMenu';
import { isHzaSelector } from '../../footer/redux/selectors';
import useReportRendering from '../../powerBi/hooks/useReportRendering';
import { MOOD_AND_CONFIDENCE } from '../../powerBi/constants';
import useUserActivity from '../../userActivity/hooks/useUserActivity';
import EngineeringCompetitivenessMenu from '../../powerBi/components/EngineeringCompetitivenessMenu/EngineeringCompetitivenessMenu';

export default function Header() {
  useReportRendering();
  const { init } = useLoadDependencies();
  const isHza = useSelector(isHzaSelector);
  const msal = useMsal();
  const hzaRef = useRef(null);
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const treeData = useSelector(treeDataSelector);
  const biProps = useSelector(biPropsSelector);
  const userData = user.list.data;
  const location = useLocation();
  const [showHzaBtn, setShowHzaBtn] = useState(false);
  const isDrilled = useSelector(isDrilledSelector);
  useUserActivity();

  const className = clx({
    competitiveness: /\/engineeringcompetitivness\/(.*)/.test(location.pathname),
    jobFamily: location.pathname === '/jobfamily',
  });

  useEffect(() => {
    window.addEventListener('message', event => {
      if (event.data.type === 'SYNC_FILTERS') {
        dispatch(setReportFilters(event.data.filters));
      }
    });
  }, []);

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (hzaRef && hzaRef.current && biProps.list) {
      const hzaProxyUrl = hzaRef.current.getAttribute('src');
      if (window.location.href.indexOf('localhost') !== -1) {
        dispatch(setHzaIframeLoaded(true));
      } else {
        fetch(hzaProxyUrl).then(response => {
          if (response.ok) {
            dispatch(setHzaIframeLoaded(true));
          }
        });
      }
    }
  }, [hzaRef, biProps.list]);

  useEffect(() => {
    if (!user.loading && user.list.hasOwnProperty('data')) {
      dispatch(setFavoriteOrganizations(user.list.data.favoriteOrganization));
      dispatch(setUnderMyResponsability(user.list.data.orgsUnderMyResponsability));
      dispatch(setUserOrgs(user.list.data.orgRoles.map(org => org.orgDetails.name)));
    }
  }, [user]);

  useEffect(() => {
    if (!treeData.loading && treeData.list.hasOwnProperty('data')) {
      dispatch(setGbuList(treeData.list.data.gbus));
    }
  }, [treeData]);

  useEffect(() => {
    showMoreDetailsButton(isDrilled);
    if (!isDrilled) {
      setShowHzaBtn(false);
    }
  }, [isDrilled, location.pathname, isHza]);

  useEffect(() => {
    msal.instance
      .acquireTokenSilent({ ...loginRequest })
      .then(response => {
        if (response) {
          // User is now authenticated, you can request an access token
          return msal.instance.acquireTokenSilent({ ...loginRequest });
        }
      })
      .then(tokenResponse => dispatch(setAccessToken(tokenResponse.accessToken)))
      .catch(err => {
        console.error(err);
        msal.instance.loginRedirect();
      });
  }, []);

  async function showMoreDetailsButton(isDrilled) {
    try {
      if (window.report[MOOD_AND_CONFIDENCE]) {
        const currentPage = await window.report[MOOD_AND_CONFIDENCE].getActivePage();
        if (
          currentPage.name === 'ReportSection6251a23cd0ba370500ea' &&
          window.location.pathname === '/moodandconfidence'
        ) {
          setShowHzaBtn(isDrilled);
        } else {
          setShowHzaBtn(false);
        }
      }
    } catch (e) {
      console.warn(e);
    }
  }

  const renderUserGuideBtn = () => {
    switch (location.pathname) {
      case '/landingpage':
        return (
          <LandingPageTour>
            <UserGuideButton />
          </LandingPageTour>
        );

      case '/organization':
        return (
          <OrganizationTour>
            <UserGuideButton userData={userData} />
          </OrganizationTour>
        );
      default:
    }
  };

  return (
    <>
      {biProps.list && biProps.list.data && window.self === window.top && (
        <iframe
          ref={hzaRef}
          id="myFrameHZA"
          style={{
            position: 'absolute',
            zIndex: '2',
            top: '60px',
            width: '100%',
            border: 0,
            height: 'calc(100% - 101px)',
            display: 'none',
          }}
          src={`${biProps.list.data.hzaIframeURL}/proxy.html`}
          // src={`http://localhost:8080/proxy.html`}
          //src={`/proxy.html`}
          title="description"
        />
      )}
      <StyledHeader id="header" sx={{ position: 'relative', zIndex: 2 }}>
        <EmwBrand />
        <Divider
          flexItem
          sx={{
            width: '2px',
            height: '43px',
            marginTop: '6px',
            backgroundColor: '#BCBECE',
            border: 'none',
          }}
        />
        <Box mr={3} />
        <div>
          <DiagramContextProvider>{renderUserGuideBtn()}</DiagramContextProvider>
        </div>
        <JobFamilyMenu className={className} />
        <EngineeringCompetitivenessMenu className={className} />
        <div style={{ margin: !showHzaBtn ? '0 auto ' : '' }}>
          {!showHzaBtn && <AdministrationMenu />}
        </div>

        <div style={{ margin: showHzaBtn ? '0 auto 0 30%' : '' }}>
          {!isHza && showHzaBtn && <EmwOpenHzaBtn />}
        </div>
        <div className="mr-xs" />
        <DiagramContextProvider>
          <UserMenu />
        </DiagramContextProvider>
      </StyledHeader>
    </>
  );
}
