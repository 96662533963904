import React, { useContext } from 'react';
import EmwTypography from '../../../../../../components/EmwTypography/EmwTypography';
import { BarChart } from '../../../../../../components/EmwCharts';
import { abbreviateNumber, CHART_TYPE, transformProcessedData } from '../../utils';
import DistributionTable from '../DistributionTable/DistributionTable';
import EmwWordCloud from '../../../../../../components/EmwCharts/components/EmwWordCount/EmwWordCloud';
import LoadingContainer from '../LoadingContainer/LoadingContainer';
import { HzaContext } from '../../../../../hzaSync/context/HzaContext';

export default function MoodAndDistribution({
  data,
  words,
  handleClick,
  handleBarClick,
  filteredData,
  selectedBar,
  chartType,
  isFilteredData,
}) {
  const hzaContext = useContext(HzaContext);

  return (
    <div className="flex bg-white-100 mood-and-distribution flex-1 relative">
      <LoadingContainer isLoading={hzaContext.mcdProcessDataReqStatus.isLoading} />
      <div className="flex flex-col flex-1">
        <EmwTypography classes="py-xxs pl-xxs">Mood and distribution</EmwTypography>
        <DistributionTable data={isFilteredData ? filteredData.distribution : data.distribution} />
        <div className="chart-section flex flex-1 pl-s">
          <BarChart
            isMcdDrillToDetails={true}
            hasShadow={false}
            data={data.chart}
            unit=""
            margin={{ top: 10, right: 0, bottom: 38, left: 35 }}
            axisLeft={{
              tickSize: 0,
              tickPadding: 5,
              tickRotation: 0,
              legend: 'Mood Number of Marks',
              legendPosition: 'middle',
              legendOffset: -31,
              format: value => abbreviateNumber(value),
            }}
            axisBottom={{
              tickSize: 0,
              tickPadding: 5,
              tickRotation: 0,
              legend: 'Mood',
              legendPosition: 'middle',
              legendOffset: 25,
              truncateTickAt: 0,
            }}
            handleClick={handleClick}
            onBarClick={handleBarClick}
            selectedItems={chartType === CHART_TYPE.MOOD ? selectedBar : null}
            isOverlayVisible={!!selectedBar || isFilteredData}
            // filteredData={chartType === CHART_TYPE.CONFIDENCE ? filteredData.chart : null}
            filteredData={isFilteredData ? filteredData.chart : null}
          />
        </div>
      </div>
      <div className="flex flex-col flex-1">
        <EmwTypography classes="py-xxs pl-xxs">Comment</EmwTypography>
        <div className="flex-1 overflow-hidden p-m">
          <EmwWordCloud
            data={words}
            height={500}
            width={700}
            type={'mood'}
            handleClick={handleClick}
          />
        </div>
      </div>
    </div>
  );
}
