import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import './index.css';
import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';

import { useVirtualizer } from '@tanstack/react-virtual';
import Stars from '../Stars/Stars';
import moment from 'moment/moment';
import EmwProgressCircle from '../../../../../../components/EmwProgressCircle/EmwProgressCircle';
import DataStatusFeedback from '../DataStatusFeedback/DataStatusFeedback';
import { useDispatch, useSelector } from 'react-redux';
import {
  getDrillResetData,
  getMcdDrillDataPayload,
  getMcdDrillRequestStatus,
  getMcdDrillToData,
  getMcdDrillToPayload,
} from '../../../../redux/selectors';
import { computeURL, fetchOptions } from '../../../../../../../util/fetch';
import { DRILL_REQUESTS } from '../DrillToDetails/constants';
import { HzaContext } from '../../../../../hzaSync/context/HzaContext';
import ProjectNamesSelector from './components/ProjectNamesSelector/ProjectNamesSelector';
import useRequests from '../../useRequests';
import { setMcdDrillDataPayload } from '../../../../redux/actions';
import { StyledTableWrapper } from './components/styled';
import LoadingContainer from '../LoadingContainer/LoadingContainer';

let lock = false;
let startRows = 0;

export default function DetailsTable({ tableWrapperRef, isLoading }) {
  const dispatch = useDispatch();
  const hzaContext = useContext(HzaContext);
  const tableContainerRef = useRef(null);
  const reqDataPayload = useSelector(getMcdDrillDataPayload);
  const reqPayload = useSelector(getMcdDrillToPayload);
  const shouldResetData = useSelector(getDrillResetData);
  const mcdData = useSelector(getMcdDrillToData);
  const { handleRequests } = useRequests();
  const [sorting, setSorting] = useState([]);
  const [fetching, setFetching] = useState(hzaContext.mcdDataReqStatus.isLoading || false);
  const [tableWidth, setTableWidth] = useState();
  const [tableOverflow, setTableOverflow] = useState('auto');
  const [data, setData] = useState({
    pages: [],
    // totalRows: totalRows,
  });
  const columns = useMemo(
    () => [
      {
        accessorKey: 'startDate',
        header: 'Date',
        enableSorting: true,
        cell: info => {
          return moment(info.cell.getValue()).format('yyyy/DD/MM HH:mm:ss');
        },
        size: 100,
      },
      {
        accessorKey: 'gbuCode',
        header: 'GBU',
        enableSorting: true,
        size: 70,
      },
      {
        accessorKey: 'blCode',
        header: 'Bl',
        enableSorting: true,
        size: 70,
      },
      {
        accessorKey: 'ccName',
        header: 'CC',
        enableSorting: true,
      },
      {
        accessorKey: 'country',
        header: 'Country',
        enableSorting: true,
      },
      {
        accessorKey: 'pname',
        header: 'Project',
        enableSorting: false,
      },
      {
        accessorFn: row => row.moodSatisfaction,
        id: 'moodSatisfaction',
        enableSorting: true,
        cell: info => {
          return <Stars value={info.cell.getValue()} size="small" />;
        },
        header: 'Mood',
      },
      {
        accessorKey: 'moodComment',
        header: 'Comment',
        enableSorting: true,
        size: 200,
      },
      {
        accessorFn: row => row.confindenceToDeliverSatisfaction,
        id: 'confindenceToDeliverSatisfaction',
        enableSorting: true,
        cell: info => {
          return <Stars value={info.cell.getValue()} size="small" />;
        },
        header: () => <span>Confidence</span>,
      },
      {
        accessorKey: 'confindenceToDeliverComment',
        header: 'Comment',
        enableSorting: true,
        size: 200,
      },
    ],
    []
  );

  const fetchMoreOnBottomReached = useCallback(
    containerRefElement => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
        if (Math.floor(scrollHeight - scrollTop - clientHeight) <= 0 && !fetching) {
          const _reqDataPayload = { ...reqDataPayload, ...reqPayload };
          console.log('1 ', startRows);
          startRows += _reqDataPayload.rows;
          console.log('2 ', startRows);
          _reqDataPayload.startRow = startRows;
          fetchNextPage(_reqDataPayload);
        }
      }
    },
    [reqPayload]
  );

  useEffect(() => {
    if (tableWrapperRef.current) {
      const resizeObserver = new ResizeObserver(() => {
        setTableWidth(tableWrapperRef.current.offsetWidth);
      });
      resizeObserver.observe(tableWrapperRef.current);
      return () => resizeObserver.disconnect();
    }
  }, [tableWrapperRef]);

  useEffect(() => {
    if (!data.pages.length) {
      setTableOverflow('hidden');
    } else {
      setTableOverflow('auto');
    }
  }, [data]);

  useEffect(() => {
    if (shouldResetData) {
      setData({ pages: [] });
      startRows = 0;
    }
  }, [shouldResetData]);

  useEffect(() => {
    if (mcdData.length) {
      const _currentData = { ...data };
      _currentData.pages.push(mcdData);
      setData(_currentData);
    }
  }, [mcdData]);

  useEffect(() => {
    fetchMoreOnBottomReached(tableContainerRef.current);
  }, [fetchMoreOnBottomReached]);

  useEffect(() => {
    if (hzaContext.mcdDataReqStatus.name === DRILL_REQUESTS.MCD.DATA) {
      lock = false;
      setFetching(false);
    }
  }, [hzaContext.mcdDataReqStatus]);

  const flatData = useMemo(() => {
    return data?.pages?.flat() ?? [];
  }, [data]);

  const table = useReactTable({
    data: flatData,
    columns,
    enableColumnResizing: true,
    columnResizeMode: 'onChange',
    state: {
      sorting,
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    manualSorting: true,
    // debugTable: true,
  });

  const { rows } = table.getRowModel();

  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    estimateSize: () => 33, //estimate row height for accurate scrollbar dragging
    getScrollElement: () => tableContainerRef.current,
    //measure dynamic row height, except in firefox because it measures table border height incorrectly
    measureElement:
      typeof window !== 'undefined' && navigator.userAgent.indexOf('Firefox') === -1
        ? element => element?.getBoundingClientRect().height
        : undefined,
    overscan: 5,
  });

  //scroll to top of table when sorting changes
  const handleSortingChange = updater => {
    setSorting(updater);
    if (!!table.getRowModel().rows.length) {
      rowVirtualizer.scrollToIndex?.(0);
    }
  };

  const fetchNextPage = reqPayload => {
    if (!lock) {
      lock = true;
      const mcdDataApi = computeURL('mcd', DRILL_REQUESTS.MCD.DATA);
      const mcdDataParams = fetchOptions({ method: 'POST' });

      mcdDataParams.body = JSON.stringify(reqPayload);
      setFetching(true);

      hzaContext.setMcdDataReqStatus({
        ...hzaContext.mcdDataReqStatus,
        isLoading: true,
      });
      hzaContext.handleRequest(mcdDataApi, mcdDataParams, DRILL_REQUESTS.MCD.DATA);
    }
  };

  //since this table option is derived from table row model state, we're using the table.setOptions utility
  table.setOptions(prev => ({
    ...prev,
    onSortingChange: handleSortingChange,
  }));

  const handleHeaderClick = column => {
    if (column.id === 'pname') {
      return;
    }
    setTimeout(() => {
      const _reqPayload = { ...reqDataPayload, ...reqPayload };
      const sortValue = column.getIsSorted();
      const sortObj = { id: column.id, value: column.getIsSorted() };
      const filterExists = _reqPayload.sorting.find(filter => filter.id === column.id);

      if (sortValue) {
        _reqPayload.sorting = [sortObj];
      } else {
        _reqPayload.sorting = [];
      }

      // if (sortValue) {
      //   if (filterExists) {
      //     _reqPayload.sorting = _reqPayload.sorting.map(filter => {
      //       if (filter.id === column.id) {
      //         filter = sortObj;
      //       }
      //
      //       return filter;
      //     });
      //   } else {
      //     _reqPayload.sorting.push(sortObj);
      //   }
      // } else {
      //   _reqPayload.sorting = _reqPayload.sorting.filter(sortObj => sortObj.id !== column.id);
      // }

      dispatch(setMcdDrillDataPayload(_reqPayload));
      handleRequests(_reqPayload);
    }, 250);
  };

  return (
    <StyledTableWrapper
      ref={tableContainerRef}
      className="table-container"
      style={{ width: tableWidth, overflow: tableOverflow, position: 'relative' }}
      onScroll={e => fetchMoreOnBottomReached(e.currentTarget)}
    >
      <LoadingContainer isLoading={hzaContext.mcdDataReqStatus.isLoading} />
      <table>
        <thead>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id} className="flex w-full _row">
              {headerGroup.headers.map(header => {
                const style = {
                  width: header.getSize(),
                };

                if (['moodComment', 'confindenceToDeliverComment'].includes(header.id)) {
                  style.flexGrow = 1;
                }

                return (
                  <th
                    key={header.id}
                    onClick={() => handleHeaderClick(header.column)}
                    className="flex text-primary-50 text-scale-8"
                    style={style}
                  >
                    <div
                      {...{
                        className: header.column.getCanSort() ? 'cursor-pointer select-none' : '',
                        onClick: header.column.getToggleSortingHandler(),
                      }}
                    >
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      {{
                        asc: ' 🔼',
                        desc: ' 🔽',
                      }[header.column.getIsSorted()] ?? null}

                      {header.id === 'pname' && <ProjectNamesSelector />}
                    </div>
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody
          className="text-scale-8 font-thin bg-white-100"
          style={{
            display: 'grid',
            height: `${rowVirtualizer.getTotalSize()}px`, //tells scrollbar how big the table is
            position: 'relative', //needed for absolute positioning of rows
          }}
        >
          {rowVirtualizer.getVirtualItems().map(virtualRow => {
            const row = rows[virtualRow.index];
            return (
              <tr
                data-index={virtualRow.index} //needed for dynamic row height measurement
                ref={node => rowVirtualizer.measureElement(node)} //measure dynamic row height
                key={row.id}
                style={{
                  display: 'flex',
                  position: 'absolute',
                  transform: `translateY(${virtualRow.start}px)`, //this should always be a `style` as it changes on scroll
                  width: '100%',
                  ':hover': { border: '1px solid red' },
                }}
              >
                {row.getVisibleCells().map(cell => {
                  const style = {
                    width: cell.column.getSize(),
                  };

                  if (['moodComment', 'confindenceToDeliverComment'].includes(cell.column.id)) {
                    // style.width = '100%';
                    style.flexGrow = 1;
                  }

                  return (
                    <td key={cell.id} className="flex" style={style}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {/*{true || fetching && (*/}
      {/*)}*/}
      {!fetching && !flatData.length && <DataStatusFeedback />}
    </StyledTableWrapper>
  );
}
