import React, { Suspense, useEffect, useState } from 'react';
import { Resizable } from 're-resizable';
import { useDispatch, useSelector } from 'react-redux';
import { FILTERS_SECTIONS, RESIZABLE, RESIZABLE_CLOSED } from './constants';
import { StyledEmwGeneralFilters } from './components/styled';
import GeneralFiltersHeader from './components/GeneralFiltersHeader/GeneralFiltersHeader';
import GeneralFiltersWrapper from './components/GeneralFiltersWrapper/GeneralFiltersWrapper';
import GeneralFiltersCard from './components/GeneralFiltersCard/GeneralFiltersCard';
import { useLocation } from 'react-router-dom-v5-compat';
import { treeDataSelector } from '../../features/header/redux/selectors';
import useGeneralFilters from './hooks/useGeneralFilters';
import {
  availableFiltersSelector,
  selectedGeneralFilters,
  availableDrillToDetailFiltersSelector,
  selectedDrillToDetailFilters,
} from './redux/selectors';
import { clearAllFilters } from './redux/actions';
import { generateRandom } from '../../../util/generateRandom';

export default function EmwGeneralFilters({ onResize, param }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const [isExpanded, setIsExpanded] = useState(true);
  const { buildFilters, buildFiltersWithParam } = useGeneralFilters();
  const treeData = useSelector(treeDataSelector);
  const availableFilters = useSelector(availableFiltersSelector);
  // const selectedFilters = useSelector(selectedGeneralFilters);
  const selectedDrillFilters = useSelector(selectedDrillToDetailFilters);
  const availableDrillToDetailFilters = useSelector(availableDrillToDetailFiltersSelector);
  const isDrilled =
    window.location.hash.includes('#drilltodetails') ||
    location.pathname.includes('/drill-to-details');

  let items = [];
  useEffect(() => {
    return () => {
      dispatch(clearAllFilters(generateRandom()));
    };
  }, []);

  useEffect(() => {
    if (
      treeData.list.hasOwnProperty('data') &&
      selectedDrillFilters.country.length === 0 &&
      selectedDrillFilters.bl.length === 0 &&
      selectedDrillFilters.cc.length === 0 &&
      selectedDrillFilters.gbu.length === 0
    ) {
      // buildFilters(treeData.list.data);
      if (isDrilled) {
        buildFiltersWithParam(treeData.list.data, param); // + param
      } else {
        console.log('!!!!!selectedDrillFilters isNotDrilled');
        buildFiltersWithParam(treeData.list.data);
      }
    }
  }, [treeData, selectedDrillFilters, isDrilled]);

  const handleToggleFilters = event => {
    setIsExpanded(!isExpanded);
  };

  // const getAvailableOrganization = () => {
  //   // if (isDrilled) {
  //   //   console.log('isDrilled');
  //   //   items = availableDrillToDetailFilters;
  //   // } else {
  //   //   console.log('else is not dreill');
  //   //   items = availableDrillToDetailFilters;
  //   // }

  //   // console.log('items', items);

  //   return availableDrillToDetailFilters;
  // };
  return (
    <>
      {isExpanded && (
        <Resizable {...RESIZABLE} onResize={onResize}>
          <StyledEmwGeneralFilters>
            <Suspense fallback={'Loading ...'}>
              <GeneralFiltersHeader toggle={handleToggleFilters} isExpanded={isExpanded} />
              <GeneralFiltersWrapper>
                <GeneralFiltersCard
                  type={FILTERS_SECTIONS.COUNTRY}
                  items={availableDrillToDetailFilters.country}
                />
                <GeneralFiltersCard
                  type={FILTERS_SECTIONS.GBU}
                  items={availableDrillToDetailFilters.gbu}
                />
                <GeneralFiltersCard
                  type={FILTERS_SECTIONS.BL}
                  items={availableDrillToDetailFilters.bl}
                />
                <GeneralFiltersCard
                  type={FILTERS_SECTIONS.CC}
                  items={availableDrillToDetailFilters.cc}
                />
              </GeneralFiltersWrapper>
            </Suspense>
          </StyledEmwGeneralFilters>
        </Resizable>
      )}
      {!isExpanded && (
        <StyledEmwGeneralFilters {...RESIZABLE_CLOSED}>
          <GeneralFiltersHeader toggle={handleToggleFilters} isExpanded={isExpanded} />
        </StyledEmwGeneralFilters>
      )}
    </>
  );
}
