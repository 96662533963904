import { styled } from '@mui/material';

export const StyledTableWrapper = styled('div')(({}) => ({
  tbody: {
    tr: {
      '&:hover': {
        opacity: '0.725',
      },
    },
  },
}));
