import { createAction } from '@reduxjs/toolkit';
import * as api from './api';

const props = {
  error: false,
  meta: { fetching: false },
};

export const CHARTS = 'charts';
export const ALERTS = 'alerts';
export const COMMENTS = 'comments';
export const CREATE_TARGET = 'create_target';
export const GET_TARGETS = 'get_targets';
export const SHOULD_RENDER_LINES = 'should_render_lines';
export const GENERAL_FILTERS = 'general_filters';
export const CAN_ADD_TARGETS = 'can_add_targets';
export const CAN_ADD_ALERTS = 'can_add_alerts';
export const USER_ORGANIZATIONS = 'user_organizations';
export const ALERT_HISTORY_MODAL = 'alert_history_modal';
export const POWER_BI_INITIALIZED = 'powerBiInitialized';
export const MY_COMMENTS_HISTORY_MODAL = 'comment_history_modal';
export const SELECTED_REPORT = 'selected_report';
export const IS_DRILLED = 'is_drilled';
export const COMMENTS_ROLES = 'comments_roles';
export const ACCESS_TOKEN = 'access_token';
export const REPORT_FILTERS = 'report_filters';
export const REPORT_FILTERS_HZA = 'report_filters_hza';
export const EMBEDDED_URL = 'EMBEDDED_URL';

export const MCD_DRILL_TO_PAYLOAD = 'MCD_DRILL_TO_PAYLOAD';
export const MCD_DRILL_TO_DATA = 'MCD_DRILL_TO_DATA';
export const MCD_DRILL_PROCESS_DATA = 'MCD_DRILL_PROCESS_DATA';
export const MCD_DRILL_PROCESS_FILTERED_DATA = 'MCD_DRILL_PROCESS_FILTERED_DATA';
export const MCD_DRILL_DATA_PAYLOAD = 'MCD_DRILL_DATA_PAYLOAD';
export const MCD_DRILL_RESET_DATA = 'MCD_DRILL_RESET_DATA';
export const MCD_PROJECTS_DATA = 'MCD_DRILL_PROJECTS_DATA';
export const MCD_DRILL_REQUEST_STATUS = 'MCD_DRILL_REQUEST_STATUS';
export const MCD_DRILL_TO_IS_FILTERED_DATA = 'MCD_DRILL_TO_IS_FILTERED_DATA';

export const updateCharts = createAction(CHARTS);
export const updateAlerts = createAction(ALERTS);
export const updateComments = createAction(COMMENTS);
export const setShouldRenderLines = createAction(SHOULD_RENDER_LINES);
export const setCanAddTarget = createAction(CAN_ADD_TARGETS);
export const setCanAddAlerts = createAction(CAN_ADD_ALERTS);
export const setUserOrgs = createAction(USER_ORGANIZATIONS);
export const setPowerBiInitialized = createAction(POWER_BI_INITIALIZED);
export const getTargets = createAction(GET_TARGETS, payload => ({
  ...props,
  payload: api.getTargetsApi(payload),
}));
export const alertHistoryModal = createAction(ALERT_HISTORY_MODAL);
export const commentsHistoryModal = createAction(MY_COMMENTS_HISTORY_MODAL);
export const setSelectedReport = createAction(SELECTED_REPORT);
export const setIsDrilled = createAction(IS_DRILLED);
export const setCommentsRoles = createAction(COMMENTS_ROLES);
export const setAccessToken = createAction(ACCESS_TOKEN);
export const setReportFilters = createAction(REPORT_FILTERS);
export const setReportFiltersHza = createAction(REPORT_FILTERS_HZA);
export const setEmbeddedUrl = createAction(EMBEDDED_URL);

export const setMcdDrillToPayload = createAction(MCD_DRILL_TO_PAYLOAD);
export const setMcdDrillToData = createAction(MCD_DRILL_TO_DATA);
export const setMcdDrillProcessData = createAction(MCD_DRILL_PROCESS_DATA);
export const setMcdDrillProcessFilteredData = createAction(MCD_DRILL_PROCESS_FILTERED_DATA);
export const setMcdDrillDataPayload = createAction(MCD_DRILL_DATA_PAYLOAD);
export const setDrillResetData = createAction(MCD_DRILL_RESET_DATA);
export const setProjectsData = createAction(MCD_PROJECTS_DATA);
export const setDrillRequestStatus = createAction(MCD_DRILL_REQUEST_STATUS);
export const setIsFilteredMcdData = createAction(MCD_DRILL_TO_IS_FILTERED_DATA);
