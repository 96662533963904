import React, { useEffect, useState, useRef, useMemo, useLayoutEffect } from 'react';
import cloud from 'd3-cloud';
import { scaleOrdinal } from 'd3-scale';
import { schemeCategory10 } from 'd3-scale-chromatic';
import { useDispatch, useSelector } from 'react-redux';
import { getMcdDrillToPayload } from '../../../../features/powerBi/redux/selectors';
import { setMcdDrillToPayload } from '../../../../features/powerBi/redux/actions';
import DataStatusFeedback from '../../../../features/powerBi/components/MoodAndConfidence/components/DataStatusFeedback/DataStatusFeedback';
import useRequests from '../../../../features/powerBi/components/MoodAndConfidence/useRequests';
import { setIsFilteredMcdData } from '../../../../features/powerBi/redux/actions';

/**
 * @TODO: La click pe cuvand trebuie executate requesturile de: mcdProcessData si de
 * @param data
 * @param width
 * @param height
 * @param type
 * @returns {Element}
 * @constructor
 */

export default function EmwWordCloud({ data, width, height, type, handleClick }) {
  const [selectedWords, setSelectedWords] = useState(null);
  const [hoveredWord, setHoveredWord] = useState(null);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
  const [layout, setLayout] = useState([]);
  const colorScale = scaleOrdinal(schemeCategory10);
  const cacheRef = useRef(new Map());
  const coloredWords = useMemo(() => {
    return data.map((word, index) => ({ ...word, color: colorScale(index) }));
  }, [data]);
  const mcdReqPayload = useSelector(getMcdDrillToPayload);
  const dispatch = useDispatch();
  const prop = `${type}Words`;
  const { handleRequests } = useRequests();

  useEffect(() => {
    const cacheKey = JSON.stringify(coloredWords);
    if (cacheRef.current.has(cacheKey)) {
      setLayout(cacheRef.current.get(cacheKey));
    } else {
      cloud()
        .size([width, height])
        .words(
          coloredWords.map(d => ({
            ...d,
            size: calculateWordSize(d.value, data),
          }))
        )
        .padding(5)
        .rotate(d => d.value % 1)
        .fontSize(d => d.size)
        .on('end', words => {
          setLayout(words);
          cacheRef.current.set(cacheKey, words);
        })
        .start();
    }
  }, [data]);

  useEffect(() => {
    if (!selectedWords) {
      return;
    }
    if (selectedWords.length > 0) {
      dispatch(setIsFilteredMcdData(true));
    } else {
      dispatch(setIsFilteredMcdData(false));
    }
    const _mcdReqPayload = { ...mcdReqPayload };
    _mcdReqPayload[prop] = selectedWords;
    dispatch(setMcdDrillToPayload(_mcdReqPayload));
    handleRequests(_mcdReqPayload, [prop]);
  }, [selectedWords]);

  const handleWordClick = (event, word) => {
    let _selectedWords = selectedWords || [];

    const isWordSelected = _selectedWords.includes(word.text);

    if (!event.shiftKey) {
      //daca e click normal
      if (_selectedWords.length === 0) {
        //daca nu avem niciun cuvant selectat
        setSelectedWords([word.text]);
      } else if (_selectedWords.length === 1 && isWordSelected) {
        //daca dam click din nou pe acelasi cuvant selectat pt a il deselecta
        setSelectedWords([]);
      } else {
        //daca dam click pe alt cuvant
        setSelectedWords([word.text]);
      }
    } else {
      //daca e shift apasat
      setSelectedWords(prevSelectedWords =>
        isWordSelected
          ? prevSelectedWords.filter(item => item !== word.text)
          : [...prevSelectedWords, word.text]
      );
    }
  };

  const handleMouseEnter = (event, word) => {
    const hoverText = `${word.text} ${word.value}`;
    setHoveredWord(hoverText);

    setTooltipPosition({
      left: event.clientX + 15,
      top: event.clientY,
    });
  };

  const handleMouseLeave = () => {
    setHoveredWord(null);
  };

  const calculateWordSize = (value, words) => {
    const maxValue = Math.max(...words.map(w => w.value));
    const minValue = Math.min(...words.map(w => w.value));
    const MAX_FONT_SIZE = 100;
    const MIN_FONT_SIZE = 30;
    const percentage = (value - minValue) / (maxValue - minValue);
    const fontSize = MIN_FONT_SIZE + percentage * (MAX_FONT_SIZE - MIN_FONT_SIZE);

    return fontSize;
  };

  if (!data.length) {
    return (
      <div className="flex" style={{ height: 300 }}>
        <DataStatusFeedback />
      </div>
    );
  }

  const getOpacity = word => {
    if (!Array.isArray(selectedWords)) {
      return 1;
    }

    return !selectedWords.length || selectedWords.includes(word.text) ? 1 : 0.3;
  };

  return (
    <div className="emw-word-cloud-text">
      <svg viewBox="0 0 700 500" preserveAspectRatio="xMinYMin meet">
        <g transform={`translate(${width / 2},${height / 2})`}>
          {layout.map((word, i) => (
            <text
              key={i}
              className="transition-opacity duration-300 cursor-pointer"
              style={{
                fontSize: `${word.size}px`,
                opacity: getOpacity(word),
                fontFamily: `'Segoe UI', 'wf_segoe-ui_normal', helvetica, arial, sans-serif`,
                fill: word.color,
              }}
              textAnchor="middle"
              transform={`translate(${word.x}, ${word.y}) rotate(0)`}
              onClick={event => handleWordClick(event, word)}
              onMouseEnter={event => handleMouseEnter(event, word)}
              onMouseLeave={handleMouseLeave}
            >
              {word.text}
            </text>
          ))}
        </g>
      </svg>

      {hoveredWord && (
        <div
          style={{
            position: 'fixed',
            top: tooltipPosition.top,
            left: tooltipPosition.left,
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            color: 'white',
            padding: '5px',
            borderRadius: '3px',
            transform: 'translate(0, -50%)',
            whiteSpace: 'nowrap',
            zIndex: 1000,
          }}
        >
          <div
            style={{
              position: 'absolute',
              top: '50%',
              right: '100%',
              marginTop: '-5px',
              borderWidth: '5px',
              borderStyle: 'solid',
              borderColor: 'transparent black transparent transparent',
            }}
          />
          {hoveredWord}
        </div>
      )}
    </div>
  );
}
