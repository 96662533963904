import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setAvailableFilters,
  setGeneralFilters,
  setAvailableDrillToDetailFilters,
  setGeneralDrillToDetailFilters,
} from '../redux/actions';
import {
  generalFiltersSelectors,
  selectedGeneralFilters,
  generalDrillToDetailFiltersSelectors,
  selectedDrillToDetailFilters,
} from '../redux/selectors';
import { FILTERS_SECTIONS } from '../constants';

export const SELECT_ALL_ID = 'selectAll';

export default function useGeneralFilters() {
  const dispatch = useDispatch();
  // const selectedFilters = useSelector(selectedGeneralFilters);
  const selectedDrillFilters = useSelector(selectedDrillToDetailFilters);
  // const generalFilters = useSelector(generalFiltersSelectors);
  const generalDrillToDetailFilters = useSelector(generalDrillToDetailFiltersSelectors);
  /**
   * Generate selected filters structure
   */
  // useEffect(() => {
  //   handleSelectedFilters(selectedFilters, generalFilters);
  //   console.log('general-------)');
  // }, [selectedFilters]);

  useEffect(() => {
    handleSelectedFilters(selectedDrillFilters, generalDrillToDetailFilters);
  }, [selectedDrillFilters]);

  const handleSelectedFilters = (filters, allFilters) => {
    const selectedCountries = filters[FILTERS_SECTIONS.COUNTRY];
    const selectedGbus = filters[FILTERS_SECTIONS.GBU];
    const selectedBls = filters[FILTERS_SECTIONS.BL];
    const selectedCCs = filters[FILTERS_SECTIONS.CC];

    let countries = [];
    let gbus = [];
    let bls = [];
    let ccs = [];

    const _filters = { ...allFilters };

    if (selectedCountries.length) {
      gbus = [];
      bls = [];
      ccs = [];
      selectedCountries.forEach(selectedCountry => {
        if (selectedCountry.childs.length > 0) {
          selectedCountry.childs.forEach(gbu => {
            const gbuWithParent = _filters.gbu.find(item => item.id === gbu.id);
            if (gbuWithParent && !gbus.some(existingGbu => existingGbu.id === gbuWithParent.id)) {
              gbus.push(gbuWithParent);
            }

            gbu.childs.forEach(bl => {
              const blWithParent = _filters.bl.find(item => item.id === bl.id);
              if (blWithParent && !bls.some(existingBl => existingBl.id === blWithParent.id)) {
                bls.push(blWithParent);
              }

              bl.childs.forEach(cc => {
                const ccWithParent = _filters.cc.find(item => item.id === cc.id);
                if (ccWithParent && !ccs.some(existingCc => existingCc.id === ccWithParent.id)) {
                  ccs.push(ccWithParent);
                }
              });
            });
          });
        }
      });

      _filters.gbu = gbus;
      _filters.bl = bls;
      _filters.cc = ccs;
    }

    if (selectedGbus.length) {
      countries = [];
      bls = [];
      ccs = [];

      selectedGbus.forEach(gbu => {
        gbu.parents.forEach(country => {
          const countryWithParent = _filters.country.find(item => item.id === country.id);
          if (
            countryWithParent &&
            !countries.some(existingCountry => existingCountry.id === countryWithParent.id)
          ) {
            countries.push(countryWithParent);
          }
        });
        _filters.bl.filter(bl => {
          bl.parents.forEach(parent => {
            if (parent.id === gbu.id) {
              const blWithParent = _filters.bl.find(item => item.id === bl.id);
              if (blWithParent && !bls.some(existingBl => existingBl.id === blWithParent.id)) {
                bls.push(blWithParent);
              }

              _filters.cc.forEach(cc => {
                const ccWithParent = _filters.cc.find(item => item.id === cc.id);
                ccWithParent.parents.forEach(ccParent => {
                  if (ccParent.id === bl.id) {
                    if (
                      ccWithParent &&
                      !ccs.some(existingCc => existingCc.id === ccWithParent.id)
                    ) {
                      ccs.push(ccWithParent);
                    }
                  }
                });
              });
            }
          });
        });
      });

      _filters.country = countries;
      _filters.bl = bls;
      _filters.cc = ccs;
    }

    if (selectedBls.length) {
      countries = [];
      gbus = [];
      ccs = [];
      selectedBls.forEach(bl => {
        const gbuWithParent = _filters.gbu.find(gbu =>
          bl.parents.some(parent => parent.id === gbu.id)
        );
        if (gbuWithParent && !gbus.some(gbu => gbu.id === gbuWithParent.id)) {
          gbus.push(gbuWithParent);
        }

        _filters.cc.filter(cc => {
          cc.parents.forEach(parent => {
            if (parent.id === bl.id) {
              const ccWithParent = _filters.cc.find(item => item.id === cc.id);
              if (ccWithParent && !ccs.some(existingCc => existingCc.id === ccWithParent.id)) {
                ccs.push(ccWithParent);
              }
            }
          });
        });
      });

      gbus.forEach(gbu => {
        gbu.parents.forEach(parent => {
          const countryWithParent = _filters.country.find(item => item.id === parent.id);
          if (
            countryWithParent &&
            !countries.some(country => country.id === countryWithParent.id)
          ) {
            countries.push(countryWithParent);
          }
        });
      });

      _filters.cc = ccs;
      _filters.gbu = gbus;
      _filters.country = countries;
    }

    if (selectedCCs.length) {
      countries = [];
      gbus = [];
      bls = [];

      selectedCCs.forEach(cc => {
        cc.parents.forEach(parent => {
          const blWithParent = _filters.bl.find(bl => bl.id === parent.id);
          if (blWithParent && !bls.some(bl => bl.id === blWithParent.id)) {
            bls.push(blWithParent);
          }
        });
        _filters.country.filter(country => {
          country.childs.forEach(gbu => {
            gbu.childs.forEach(bl => {
              bl.childs.forEach(child => {
                if (cc.id === child.id && !countries.some(item => item.id === country.id)) {
                  countries.push(country);
                }
              });
            });
          });
        });
      });

      bls.forEach(bl => {
        const gbuWithParent = _filters.gbu.find(gbu => gbu.id === bl.parent.id);
        if (gbuWithParent && !gbus.some(gbu => gbu.id === gbuWithParent.id)) {
          gbus.push(gbuWithParent);
        }
      });

      _filters.bl = bls;
      _filters.gbu = gbus;
      _filters.country = countries;
    }
    dispatch(setAvailableFilters(_filters));
    dispatch(setAvailableDrillToDetailFilters(_filters));

    // console.log('handleSelectFilters', _filters);
  };

  const removeDuplicates = data => {
    const _data = data.sort((a, b) => {
      const keyA = a.code || a.name;
      const keyB = b.code || b.name;
      if (keyA > keyB) {
        return 1;
      }
      if (keyA < keyB) {
        return -1;
      }
      return 0;
    });

    const uniqueMap = new Map();

    _data.forEach(obj => {
      const objectId = obj.id;
      if (!uniqueMap.has(objectId)) {
        uniqueMap.set(objectId, { ...obj, parents: [] });
      }
      const uniqueObj = uniqueMap.get(objectId);
      if (obj.parent) {
        uniqueObj.parents.push(obj.parent);
      }
    });

    return Array.from(uniqueMap.values());
  };

  /**
   * Generate inital filters structure
   * @param treeData
   */

  const buildFilters = treeData => {
    const countryList = [];
    const gbuList = [];
    const blList = [];
    const ccList = [];

    treeData.regions.forEach(region => {
      region.childs.forEach(country => {
        countryList.push({
          id: country.id,
          code: country.name,
          childs: country.childs,
          isVisible: true,
          checked: false,
        });

        country.childs.forEach(gbu => {
          gbuList.push({
            id: gbu.id,
            code: gbu.code,
            parent: country,
            childs: gbu.childs,
            isVisible: true,
            checked: false,
          });

          gbu.childs.forEach(bl => {
            blList.push({
              id: bl.id,
              code: bl.code,
              parent: gbu,
              childs: bl.childs.map(cc => {
                cc.parent = bl;
                return cc;
              }),
              isVisible: true,
              checked: false,
            });

            bl.childs.forEach(cc => {
              ccList.push({
                id: cc.id,
                code: cc.name,
                parent: bl,
                childs: cc.childs,
                isVisible: true,
                checked: false,
                ccType: cc.ccType,
              });
            });
          });
        });
      });
    });

    const filters = {
      country: removeDuplicates(countryList),
      gbu: removeDuplicates(gbuList),
      bl: removeDuplicates(blList),
      cc: removeDuplicates(ccList),
    };

    dispatch(setGeneralFilters(filters));
    dispatch(setAvailableFilters(filters));
  };

  const buildDrillToDetailsFilters = treeData => {
    const restrictedCountryList = [];
    const restrictedGbuList = [];
    const restrictedBlList = [];
    const restrictedCcList = [];

    treeData.regions.forEach(region => {
      region.childs.forEach(country => {
        // If showInRestricted is true, add to restrictedCountryList
        if (country.showInRestricted) {
          restrictedCountryList.push({
            id: country.id,
            code: country.name,
            childs: country.childs,
            isVisible: true,
            checked: false,
          });
        }

        country.childs.forEach(gbu => {
          // If showInRestricted is true, add to restrictedGbuList
          if (gbu.showInRestricted) {
            restrictedGbuList.push({
              id: gbu.id,
              code: gbu.code,
              parent: country,
              childs: gbu.childs,
              isVisible: true,
              checked: false,
            });
          }

          gbu.childs.forEach(bl => {
            // If showInRestricted is true, add to restrictedBlList
            if (bl.showInRestricted) {
              restrictedBlList.push({
                id: bl.id,
                code: bl.code,
                parent: gbu,
                childs: bl.childs.map(cc => {
                  cc.parent = bl;
                  return cc;
                }),
                isVisible: true,
                checked: false,
              });
            }

            bl.childs.forEach(cc => {
              // If showInRestricted is true, add to restrictedCcList
              if (cc.showInRestricted) {
                restrictedCcList.push({
                  id: cc.id,
                  code: cc.name,
                  parent: bl,
                  childs: cc.childs,
                  isVisible: true,
                  checked: false,
                  ccType: cc.ccType,
                });
              }
            });
          });
        });
      });
    });

    const restrictedFilters = {
      country: removeDuplicates(restrictedCountryList),
      gbu: removeDuplicates(restrictedGbuList),
      bl: removeDuplicates(restrictedBlList),
      cc: removeDuplicates(restrictedCcList),
    };
    dispatch(setGeneralDrillToDetailFilters(restrictedFilters));
    dispatch(setAvailableDrillToDetailFilters(restrictedFilters));
  };

  const buildFiltersWithParam = (treeData, param = null) => {
    const countryList = [];
    const gbuList = [];
    const blList = [];
    const ccList = [];

    treeData.regions.forEach(region => {
      region.childs.forEach(country => {
        if (param === null || country[param]) {
          countryList.push({
            id: country.id,
            code: country.name,
            childs: country.childs,
            isVisible: true,
            checked: false,
          });
        }

        country.childs.forEach(gbu => {
          if (param === null || gbu[param]) {
            gbuList.push({
              id: gbu.id,
              code: gbu.code,
              parent: country,
              childs: gbu.childs,
              isVisible: true,
              checked: false,
            });
          }

          gbu.childs.forEach(bl => {
            if (param === null || bl[param]) {
              blList.push({
                id: bl.id,
                code: bl.code,
                parent: gbu,
                childs: bl.childs.map(cc => {
                  cc.parent = bl;
                  return cc;
                }),
                isVisible: true,
                checked: false,
              });
            }

            bl.childs.forEach(cc => {
              if (param === null || cc[param]) {
                ccList.push({
                  id: cc.id,
                  code: cc.name,
                  parent: bl,
                  childs: cc.childs,
                  isVisible: true,
                  checked: false,
                  ccType: cc.ccType,
                });
              }
            });
          });
        });
      });
    });

    const filters = {
      country: removeDuplicates(countryList),
      gbu: removeDuplicates(gbuList),
      bl: removeDuplicates(blList),
      cc: removeDuplicates(ccList),
    };

    dispatch(setGeneralDrillToDetailFilters(filters));
    dispatch(setAvailableDrillToDetailFilters(filters));
    console.log('new filters with param', filters);
  };

  return {
    buildFilters,
    buildDrillToDetailsFilters,
    handleSelectedFilters,
    buildFiltersWithParam,
  };
}
