import initialState from '../../organization/redux/initialstate';
import * as actions from '../../organization/redux/actions';
import { getOrganizationDetailsApi } from '../../organization/redux/api';
import {
  getBiProps,
  getTreeData,
  getUser,
  setDependenciesLoaded,
  setPbiC3Urls,
} from '../redux/actions';
import { updateSchema } from '../../organization/redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { setIsHza } from '../../footer/redux/actions';
import { biPropsSelector, hzaIframeSelector, treeDataSelector } from '../redux/selectors';
import { HZA_IFRAME_ID } from '../../hzaSync/constants';
import { getMcdDrillToPayload } from '../../powerBi/redux/selectors';
import { setMcdDrillToPayload } from '../../powerBi/redux/actions';

export const useLoadDependencies = (deps = []) => {
  const dispatch = useDispatch();
  const biProps = useSelector(biPropsSelector);
  const hzaIframeLoaded = useSelector(hzaIframeSelector);
  const [loaded, setLoaded] = useState(false);
  const mcdReqPayload = useSelector(getMcdDrillToPayload);
  const treeData = useSelector(treeDataSelector);

  useEffect(() => {
    if (hzaIframeLoaded && biProps.list.hasOwnProperty('data')) {
      handleC3Urls(biProps.list.data);
    }
  }, [hzaIframeLoaded, biProps]);

  useEffect(() => {
    if (treeData.list.hasOwnProperty('data')) {
      const _mcdReqPayload = { ...mcdReqPayload };
      _mcdReqPayload.orgs = treeData.list.data.orgs;
      dispatch(setMcdDrillToPayload(_mcdReqPayload));
    }
  }, [treeData]);

  const init = async () => {
    const currentSchema = { ...initialState[actions.DIAGRAM_SCHEMA] };
    const requests = [
      await getOrganizationDetailsApi({
        id: '00053af6-7fba-4db8-8572-2d7e23332b6e',
        type: 'GROUP_COUNTRY',
        onlyChild: true,
      }),
      await getOrganizationDetailsApi({
        id: '00053af6-7fba-4db8-8572-2d7e23332b6e',
        type: 'GROUP',
        onlyChild: true,
      }),
    ];

    Promise.all(requests)
      .then(responses => {
        responses.forEach(response => {
          const data = response.data;
          const type = data.cardDetails.type;
          const childs = data.childs;

          if (type === 'GROUP_COUNTRY') {
            currentSchema.defaultChild.BY_COUNTRY = {
              type: 'REGION',
              nodes: childs,
            };
          } else {
            currentSchema.defaultChild.BY_GBU = {
              type: 'GBU',
              nodes: childs,
            };
          }
        });
      })
      .finally(() => {
        dispatch(getUser());
        dispatch(getTreeData());
        dispatch(getBiProps());
        dispatch(updateSchema(currentSchema));
        dispatch(setIsHza(false));
        dispatch(setDependenciesLoaded(true));
        setLoaded(true);
      });
  };

  /**
   * Get the C3 urls and post them to proxy file for iframes generation
   *
   * @param urls
   */
  const handleC3Urls = urls => {
    const hzaIframe = document.getElementById(HZA_IFRAME_ID).contentWindow;
    const c3Urls = Object.keys(urls)
      .filter(key => /c3/.test(key.toLowerCase()))
      .map(key => ({ id: key, value: urls[key] }));

    dispatch(setPbiC3Urls(c3Urls));
    hzaIframe.postMessage({ type: 'PBI_C3_URLS', c3Urls }, '*');
  };

  return {
    init,
    loaded,
  };
};
