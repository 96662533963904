import React, { useContext, useEffect, useState } from 'react';
import { computeURL, fetchOptions } from '../../../../../util/fetch';
import { DRILL_REQUESTS } from './components/DrillToDetails/constants';
import { HzaContext } from '../../../hzaSync/context/HzaContext';
import { useDispatch, useSelector } from 'react-redux';
import {
  getMcdDrillDataPayload,
  getMcdDrillRequestStatus,
  getMcdDrillToPayload,
} from '../../redux/selectors';
import {
  setDrillRequestStatus,
  setDrillResetData,
  setMcdDrillProcessData,
  setMcdDrillProcessFilteredData,
  setMcdDrillToData,
  setProjectsData,
} from '../../redux/actions';

const mcdProcessDataApi = computeURL('mcd', DRILL_REQUESTS.MCD.PROCESS_DATA);
const mcdDataApi = computeURL('mcd', DRILL_REQUESTS.MCD.DATA);
const projectsApi = computeURL('mcd', DRILL_REQUESTS.MCD.PROJECTS);
const mcdMoodWordsApi = computeURL('mcd', DRILL_REQUESTS.MCD.MOOD_WORDS);
const mcdConfidenceWordsApi = computeURL('mcd', DRILL_REQUESTS.MCD.CONFIDENCE_WORDS);

const mcdProcessDataParams = fetchOptions({ method: 'POST' });
const mcdDataParams = fetchOptions({ method: 'POST' });
const projectsParams = fetchOptions({ method: 'POST' });
const mcdMoodWordParams = fetchOptions({ method: 'POST' });
const mcdConfidenceWordParams = fetchOptions({ method: 'POST' });

export default function useRequests() {
  const hzaContext = useContext(HzaContext);
  const reqDataPayload = useSelector(getMcdDrillDataPayload);
  const requestsStatus = useSelector(getMcdDrillRequestStatus);
  const dispatch = useDispatch();
  const [moodWords, setMoodWords] = useState([]);
  const [confidenceWords, setConfidenceWords] = useState([]);
  const [isFilteredData, setIsFilteredData] = useState(false);

  useEffect(() => {
    const response = hzaContext.mcdDataReqStatus;
    dispatch(setMcdDrillToData(response.data));
    finishRequest(DRILL_REQUESTS.MCD.DATA);
  }, [hzaContext.mcdDataReqStatus]);

  useEffect(() => {
    const response = hzaContext.mcdProcessDataReqStatus;
    if (isFilteredData) {
      dispatch(setMcdDrillProcessFilteredData(response.data));
    } else {
      dispatch(setMcdDrillProcessData(response.data));
    }
  }, [hzaContext.mcdProcessDataReqStatus]);

  useEffect(() => {
    const response = hzaContext.mcdMoodWordsDataReqStatus;
    setMoodWords(response.data);
  }, [hzaContext.mcdMoodWordsDataReqStatus]);

  useEffect(() => {
    const response = hzaContext.mcdConfWordsDataReqStatus;
    setConfidenceWords(response.data);
  }, [hzaContext.mcdConfWordsDataReqStatus]);

  useEffect(() => {
    const response = hzaContext.mcdProjectsDataReqStatus;
    dispatch(
      setProjectsData(
        response.data.map(project => {
          return { label: project, value: project };
        })
      )
    );
  }, [hzaContext.mcdProjectsDataReqStatus]);

  const handleRequests = async (payload, skip = []) => {
    dispatch(setDrillResetData(true));
    const _reqDataPayload = { ...reqDataPayload, ...payload };
    mcdProcessDataParams.body = JSON.stringify(payload);
    mcdMoodWordParams.body = JSON.stringify(payload);
    mcdConfidenceWordParams.body = JSON.stringify(payload);
    mcdDataParams.body = JSON.stringify(_reqDataPayload);
    projectsParams.body = JSON.stringify(_reqDataPayload);

    if (!skip.includes(DRILL_REQUESTS.MCD.DATA)) {
      makeRequest(mcdDataApi, mcdDataParams, DRILL_REQUESTS.MCD.DATA);
    }

    if (!skip.includes(DRILL_REQUESTS.MCD.PROCESS_DATA)) {
      makeRequest(mcdProcessDataApi, mcdProcessDataParams, DRILL_REQUESTS.MCD.PROCESS_DATA);
    }

    if (!skip.includes(DRILL_REQUESTS.MCD.MOOD_WORDS)) {
      makeRequest(mcdMoodWordsApi, mcdMoodWordParams, DRILL_REQUESTS.MCD.MOOD_WORDS);
    }

    if (!skip.includes(DRILL_REQUESTS.MCD.CONFIDENCE_WORDS)) {
      makeRequest(
        mcdConfidenceWordsApi,
        mcdConfidenceWordParams,
        DRILL_REQUESTS.MCD.CONFIDENCE_WORDS
      );
    }

    if (!skip.includes(DRILL_REQUESTS.MCD.PROJECTS)) {
      makeRequest(projectsApi, projectsParams, DRILL_REQUESTS.MCD.PROJECTS);
    }

    setTimeout(() => {
      dispatch(setDrillResetData(false));
    }, 500);
  };

  const makeRequest = async (api, params, name) => {
    // dispatch(
    //   setDrillRequestStatus({
    //     ...requestsStatus,
    //     [DRILL_REQUESTS.MCD.DATA]: true,
    //     [DRILL_REQUESTS.MCD.PROCESS_DATA]: true,
    //     [DRILL_REQUESTS.MCD.MOOD_WORDS]: true,
    //     [DRILL_REQUESTS.MCD.CONFIDENCE_WORDS]: true,
    //     [DRILL_REQUESTS.MCD.PROJECTS]: true,
    //   })
    // );

    hzaContext.setMcdDataReqStatus({
      ...hzaContext.mcdDataReqStatus,
      isLoading: true,
    });

    hzaContext.setMcdProcessDataReqStatus({
      ...hzaContext.mcdProcessDataReqStatus,
      isLoading: true,
    });

    hzaContext.handleRequest(api, params, name);
  };

  const finishRequest = name => {
    // if (name === DRILL_REQUESTS.MCD.PROCESS_DATA) {
    //   hzaContext.setMcdDataReqStatus({
    //     ...hzaContext.mcdDataReqStatus,
    //     isLoading: false,
    //   });
    // }
    //
    //
    // hzaContext.setMcdProcessDataReqStatus({
    //   ...hzaContext.mcdProcessDataReqStatus,
    //   isLoading: true,
    // });
    // dispatch(
    //   setDrillRequestStatus({
    //     ...requestsStatus,
    //     [name]: false,
    //   })
    // );
  };

  return {
    handleRequests,
    moodWords,
    setMoodWords,
    confidenceWords,
    setConfidenceWords,
    isFilteredData,
    setIsFilteredData,
  };
}
