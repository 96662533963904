import React, { useMemo, useState } from 'react';
import { TextField, Autocomplete, Checkbox } from '@mui/material';
import { VariableSizeList } from 'react-window';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import useRequests from '../../../../useRequests';
import { useDispatch, useSelector } from 'react-redux';
import { getMcdDrillToPayload, getProjectsData } from '../../../../../../redux/selectors';
import { DRILL_REQUESTS } from '../../../DrillToDetails/constants';
import { setMcdDrillDataPayload, setMcdDrillToPayload } from '../../../../../../redux/actions';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const LISTBOX_PADDING = 8; // Padding for the list

// Outer wrapper for virtualization
const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

// Render row in virtualized list
const Row = ({ data, index, style }) => {
  return (
    <div
      style={{
        ...style,
        top: `${parseFloat(style.top)}px`,
        padding: 0,
        // borderBottom: '1px solid #ddd',
      }}
    >
      {data[index]}
    </div>
  );
};

// Virtualized ListBox
const ListboxComponent = React.forwardRef(function ListboxComponent(props, ref) {
  const { children, ...other } = props;
  const itemData = React.Children.toArray(children);
  const itemCount = itemData.length;
  const itemSize = 26; // Height of each option

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          height={Math.min(8, itemCount) * itemSize + 2 * LISTBOX_PADDING} // Show max 8 items
          width="100%"
          itemSize={() => itemSize}
          itemCount={itemCount}
          overscanCount={5} // Load extra items for smooth scrolling
          outerElementType={OuterElementType}
          itemData={itemData}
        >
          {Row}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

export default function VirtualizedAutoComplete() {
  const { handleRequests } = useRequests();
  const dispatch = useDispatch();
  const reqPayload = useSelector(getMcdDrillToPayload);
  const projects = useSelector(getProjectsData);
  const [value, setValue] = useState();

  const handleChange = (event, newValue) => {
    setValue(newValue);
    const _reqPayload = { ...reqPayload };

    _reqPayload.projects = newValue.map(project => project.value);
    dispatch(setMcdDrillToPayload(_reqPayload));
    // dispatch(setMcdDrillDataPayload(_reqPayload));

    if (newValue.length) {
      return handleRequests(_reqPayload, [DRILL_REQUESTS.MCD.PROJECTS]);
    }

    return handleRequests(_reqPayload);
  };

  return (
    <Autocomplete
      ListboxComponent={ListboxComponent}
      options={projects}
      onChange={handleChange}
      getOptionLabel={option => option.label}
      renderInput={params => <TextField {...params} />}
      renderTags={more => <span style={{ fontSize: '14px' }}>{'+' + more.length}</span>}
      disableCloseOnSelect
      disablePortal
      multiple
      disableListWrap
      limitTags={0}
      size="small"
      // renderOption={(props, option, { selected }) => {
      //   const { key, ...optionProps } = props;
      //   return (
      //     <li key={key} {...optionProps}>
      //       <Checkbox
      //         icon={icon}
      //         checkedIcon={checkedIcon}
      //         style={{ marginRight: 2 }}
      //         checked={selected}
      //       />
      //       {option.label}
      //     </li>
      //   );
      // }}
      sx={{
        '&.MuiAutocomplete-root': {
          '*': {
            border: 0,
          },
          '& .MuiInputBase-root': {
            borderRadius: 0,
            height: '18px',
            background: 'white',
            width: '142px',
            paddingRight: '42px',
            padding: '0px 4px',
            overflow: 'hidden',
            '& .MuiInputBase-input': {
              height: '12px',
              fontSize: '12px',
              borderRadius: 0,
            },
            '& .MuiAutocomplete-endAdornment': {
              marginRight: 0,
              button: {
                padding: 0,
                svg: {
                  width: '16px',
                },
              },
            },
          },
        },
      }}
    />
  );
}
